import React, { useState, useEffect } from "react";
import data from "./hiringOpportunities.json"
import "intersection-observer";
import "./hiringOpportunities.scss"
import Banner from "../../common/Banner/banner";
import ReactCarousel from "../../common/carousel/carousel";
import PopUp from "../../common/Popup/popUp"

//import data from './hiringOppurtunities.json'
import iconPathURL from "../../common/iconPath";
import { logAnalyticsEvent } from "../../utils/firebaseConfig";
const iconPathBaseUrl = process.env.PUBLIC_URL + "/assets/icons/";
const iconPath = process.env.PUBLIC_URL + "/assets/images/";

logAnalyticsEvent("MBRDI_HiringOpportunities");

export default function HiringOppurtunities(props) {
  const [showModelPopUp,setShowModelPopUp] = useState(true)
  const hiringData = data.data;

  function togglePopup() {
    setShowModelPopUp(false);
   }
   
 
  useEffect(()=>{
  },[])
    return (
        <div className="hiring-container">
           {showModelPopUp && 
      <PopUp toggleShowPopup= {() => setShowModelPopUp(false)} />
     }
          <div className="banner-parent-conetnt">
          <img src={iconPathURL('hiring/hiring_header.png')} className="banner-image"/>
         
         <div className="banner-content">
              <h1 className="banner-title">Explore limitless possibilities at MBRDI</h1>
              <p className="banner-text">
              Be the mind behind the world’s best cars, bringing luxury and style with future-next technology
              </p>
     
             
         </div>
      </div>
      
          <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-10">
       
            <p>{hiringData.p1}</p>
            <p>{hiringData.p2}</p>
            <p>{hiringData.p3}</p>
     
          </section>
            <img className="img-content" src={iconPathURL('hiring/hiring_content.png')}></img>
          <ReactCarousel
          autoPlay={5000}
          
          infiniteLoop={true}
          showStatus={false}
           count={3}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "absolute" : "hidden"
                } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                onClick={clickHandler}
              >
             <img src={`${iconPathBaseUrl}/expand.png`}/>
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "absolute" : "hidden"
                } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                onClick={clickHandler}
              >
                 <img src={`${iconPathBaseUrl}/expand.png`}/>
              </div>
            );
          }}
          >
        <div  className="img-carousel">
        <a target="new" href="https://www.techgig.com/challenge/mercedes-benz-hiring-test/">
          <img className="hiring-img" src={iconPathURL('hiring/hiring_4.png')}></img>
          </a>
        </div>
        <div  className="img-carousel">
        <a target="new" href="https://assessment.hackerearth.com/challenges/new/hiring/mercedes-benz-cloud-platform-developer-hiring-challenge-v5/">
          <img className="hiring-img" src={iconPathURL('hiring/hiring_1.png')}></img>
          </a>
        </div>
        <div className="img-carousel">
        <a target="new" href="https://assessment.hackerearth.com/challenges/new/hiring/mercedes-benz-cloud-platform-developer-hiring-challenge-v5/">
          <img className="hiring-img"  src={iconPathURL('hiring/hiring_2.png')}></img>
          </a>
        </div>
        <div className="img-carousel">
        <a target="new" href="https://assessment.hackerearth.com/challenges/new/hiring/mercedes-benz-cloud-platform-developer-hiring-challenge-v5/">
          <img className="hiring-img" src={iconPathURL('hiring/hiring_3.png')}></img>
          </a>
        </div>
        </ReactCarousel>
        </div>
    )
  }