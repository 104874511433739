
import React, { Suspense,useState, useEffect } from "react";

import $ from 'jquery';
import "./Menu.scss"
import menus from "../../Menu/Menu.json";
import sustainables_23 from "../../Menu/year_23/Sustainability_23.json";
import sustainables_24 from "../../Menu/year_24/Sustainability_24.json";
import { useHistory,useLocation } from "react-router-dom";
const iconPath = process.env.PUBLIC_URL + "/assets/docs/";
export default function Menu(props) {
    const navigate = useHistory();
    const location  = useLocation()
    const [isActive,setIsActive] = useState(false);
    const [listOfMenu,setListOfMenu] = useState(menus)
    useEffect(() => {
        if (
          navigate.location.pathname == "/sustainability-23" ||
          navigate.location.pathname == "/event-agenda-23" ||
          navigate.location.pathname == "/support-23"
        ) {
          setListOfMenu(sustainables_23);
        }else  if (
          navigate.location.pathname == "/sustainability-24" ||
          navigate.location.pathname == "/event-agenda-24" ||
          navigate.location.pathname == "/support-24"
        ) {
          setListOfMenu(sustainables_24);
     
        } else {
          setListOfMenu(menus);
        }
      });
      const navigateMbrdi =(index,links)=>{
      
            navigate.push(links['link'])
            props.toggle()
      }
    const toggleMenu = (index,links) =>{
        setIsActive(true)
        let menus = {...listOfMenu};
        console.log(menus['menus'][index]);
        if(menus['menus'][index]['isActive']){
            $("#"+index+"subLinks").slideUp("slow");
        }else{
            $("#"+index+"subLinks").slideDown("slow");
        }
       
        menus['menus'][index]['isActive'] = !menus['menus'][index]['isActive'];
        setListOfMenu(menus);
        
    }

    const toggleSubMenu = (index,subIndex) =>{
        let menus = {...listOfMenu};
        console.log(menus['menus'][index]);
        if(menus['menus'][index]['subMenu'][subIndex]['isActive']){
            $("#"+subIndex+"subLinks1").slideUp("slow");
        }else{
            $("#"+subIndex+"subLinks1").slideDown("slow");
        }
        menus['menus'][index]['subMenu'][subIndex]['isActive'] = !menus['menus'][index]['subMenu'][subIndex]['isActive']
        setListOfMenu(menus);
        
    }

        const navigateLink = (link) =>{
 
   
            if(link!=location.pathname){
             
                navigate.push(link);
               
            }
            props.toggle();
       
    }

    useEffect(()=>{
       
    },[])
    console.log('mmmm',listOfMenu);
  return (
    <div className={isActive?"t":"mobile-menu"} id="mobile-menu">
        <ul className="links">
        {listOfMenu['menus'].map((links,index) => (
            <li> 
                {links['title']=== "The MBRDI Experience"?(
                <div className="mbrdi-content">
                    <a 
                class={links['isActive']?"primary-link active":"primary-link"}  onClick={()=>navigateMbrdi(index,links)}>{links['title']} 
                    </a> 
                    <span id="menu-toggle-span" className="menu-toggle-span" style={{fontSize:18,padding:8}} onClick={()=>toggleMenu(index,links)}>{links['isActive']?"-":"+"} </span> 
                    </div>
):
            links['hasSubmenu'] && links['title']!== "The MBRDI Experience"&&
                <a 
                class={links['isActive']?"primary-link active":"primary-link"}  onClick={()=>toggleMenu(index,links)}>{links['title']}
              
                    <span className="menu-toggle">{links['isActive']?"-":"+"} </span>
                    </a>    
                       
                }
            {!links['hasSubmenu'] &&
                 <a target="new" href={links.link == "/event-report-23"?`${iconPath}Mercedes-Benz_Sustainability_Dialogue_India_2023_Report.pdf`:links['title']=="MBRDI on LinkedIn"&&"https://www.linkedin.com/company/mercedes-benz-research-and-development-india/"}
                class="primary-link"  onClick={()=>links.title!="Event Report"&&navigateLink(links['link'])}>{links['title']}
              
                  
                    </a>    
                       
                }
                
             
                {( links['hasSubmenu'])  &&
                    <ul id={index+"subLinks"} className="subLinks">
                    {links['subMenu'].map((subLinks,subIndex) => (
                            <li>
                                 {subLinks['hasSubmenu'] &&
                                <a 
                                onClick={()=>toggleSubMenu(index,subIndex)}>{subLinks['title']}
                                  <span className="menu-toggle">{subLinks['isActive']?"-":"+"} </span>
                                </a>
                                 }
                                {!subLinks['hasSubmenu'] &&
                                <a onClick={()=>navigateLink(subLinks['link'])}>{subLinks['title']}</a>
                                 }
                                  {( subLinks['hasSubmenu'])  &&
                                   <ul id={subIndex+"subLinks1"} className="subLinks">
                                   {subLinks['subMenu'].map((links) => (
                                    <li><a target="new" href={links['title']=="Year 2023"?'#/sustainability-23':links['title']=="Year 2024"?'#/sustainability-24':links['title']=="Annual Return FY21-22"?`${iconPath}Annual-Return_1.pdf`:links['title']=="Annual Return FY22-23"?`${iconPath}Annual-Return_2.pdf`:`${iconPath}AGM_Notice.pdf`} ><span class="sub-title">{links['title']}</span></a></li>
                                    //    <li>
                                    //          <a class="primary-link" target="new"  href={links['title']=="Annual Return"?"https://www.mbrdi.co.in/wp-content/uploads/2023/"+"06/Final_Notice-MBRDI-26th-AGM-FINAL.pdf":"https://www.mbrdi.co.in/wp-content/uploads/2023/"+"01/Signed-Certified-Form_MGT-7.pdf"}>{links['title']}
              
                  
                                    //                 </a> 
                                    //    </li>
                                   ))}
                                   </ul>
                                  }
                            </li>
                    ))}
                    </ul>
                 }
            </li>
        ))} 
        </ul>
       
           
    </div>
  );
}