import React, { useState, useEffect } from "react";
import "./privacy.scss";
import "intersection-observer";
import data from './privacy.json'
import { Loader } from "../../common/Loader/Loader";
import CookieConsent, { Cookies } from "react-cookie-consent";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/aboutUs/";

export default function Privacy(props) {
  const [isVisible, setVisibility] = useState(false);
  const privacy = data.data;
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
  
      window.scrollTo(0, 0);
    setTimeout(()=>{
      setLoading(false)
    },100)
  },[])

    return (
        <div className="privacy-container">
        {loading &&<Loader></Loader>}
        
        <div className="privacy-header">
            <p className="privacy-title">Privacy Statement</p>
        </div>
        {/* <Banner imgSrc={iconPathURL('aboutUs/about_us.png')} title="About us" /> */}
          <section className="text-container-section col-xs-12 col-sm-12 col-md-12 col-lg-10">
          <div className="img-content">
          <div className="second-content">
          <p className="para-content">{privacy.p1}</p>
            <p className="para-content">{privacy.p2}</p>
            <p className="para-content">{privacy.h2}</p>
            <p className="para-content">{privacy.p3}</p>
            <p className="para-content">{privacy.h3}</p>
            <p className="para-content">{privacy.p4}</p>
            <p className="para-content">{privacy.h4}</p>
            <p className="para-content">{privacy.p5}</p>
            <p className="para-content">{privacy.h5}</p>
            <p className="para-content">{privacy.p51}</p>
            <p className="para-content">{privacy.p6}</p>
            <p className="para-content">{privacy.h6}</p>
            <p className="para-content">{privacy.p7}</p>
            <p className="para-content">{privacy.p71}</p>
            <p className="address-content">{privacy.h7}</p>
            <p className="address-content">{privacy.h8}</p>
            <p className="address-content">{privacy.h9}</p>
            <p className="address-content">{privacy.h10}</p>
            <p className="address-content">{privacy.h11}</p>
            <p className="address-content">{privacy.h12}: <a href="mailto:data.protection@mercedes-benz.com">data.protection@mercedes-benz.com</a></p>
            {/* <h2 className="about-title">{aboutData.title}</h2>
            <p className="para-content">{aboutData.p1}</p>
            <p className="para-content">{aboutData.p2}</p>
            <p className="para-content">{aboutData.p3}</p> */}
          </div>
          </div>
          </section>
        </div>
    )
  }