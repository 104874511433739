import React, { useState, useEffect } from "react";
import "./whatWeDo.scss";
import "intersection-observer";
import WhatWeDoContent from "./WhatWeDoContent"
import data from './whatWeDo.json'
import { Loader } from "../../common/Loader/Loader";

import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/whatWeDo/";

export default function WhatWeDo(props) {
  const [isVisible, setVisibility] = useState(false);
  const whatWeDoData = data.data;
  const paragraph_title = data.paragraph_title;
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
  
    //  window.scrollTo(0, 0);
    setTimeout(()=>{
      setLoading(false)
    },100)
  },[])
 
    return (
        <div className="whatwedo-container">
        {loading &&<Loader></Loader>}
          
          <WhatWeDoContent whatWeDoData = {whatWeDoData} paragraph_title = {paragraph_title}/>
         
        </div>
    )
  }