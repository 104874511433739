import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

export const getFirbaseConfig = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "mbrdi-website.firebaseapp.com",
    projectId: "mbrdi-website",
    storageBucket: "mbrdi-website.appspot.com",
    messagingSenderId: "608337813985",
    appId: "1:608337813985:web:e8f95b974461767c5972a0",
    measurementId: "G-Y5KE57BJE7",
  };
  return firebaseConfig;
};
const app = initializeApp(getFirbaseConfig());
const analytics = getAnalytics(app);

export function logAnalyticsEvent(eventName) {
  logEvent(analytics, eventName);
}
