import React, { useState, useEffect, useRef } from "react";
import "./sustainability.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import data_23 from "./year_23/sustainability_1.json";
import details_23 from "./year_23/sustainability_2.json";
import data_24 from "./year_24/sustainability_1.json";
import details_24 from "./year_24/sustainability_2.json";
import speaker_details from "./sustainability_details.json";
import { Loader } from "../../common/Loader/Loader";
import Button from "../../common/Buttons/button";
import TwoColumnLayout from "../../common/TwoColumnLayout/TwoColumnLayout";
import Modal from "../../common/Modal/modal";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";

export default function Suatainability(props) {
  const agendaRef = useRef(null);
  const navigate = useHistory();
  const speakerRef = useRef(null);
  const [isVisible, setVisibility] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [sustainData, setSustainData] = useState([]);
  const [speakerData, setSpeakerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  useEffect(() => {
    //  window.scrollTo(0, 0);
    if (navigate.location.pathname == "/sustainability-23") {
      setSustainData(data_23.data_1);
      setSpeakerData(details_23.data);
    } else {
      setSustainData(data_24.data_1);
      setSpeakerData(details_24.data);
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [navigate.location.pathname]);

  const modalSpeaker = () => {
    let speakerContent = speaker_details.data[name];
    console.log("name..", name, speakerContent);
    return (
      <Modal
        show={isModalVisible}
        handleClose={() => setModalVisibility(false)}
      >
        {speakerContent && (
          <div className="table-content">
            <button
              className="button-container"
              onClick={() => setModalVisibility(false)}
            >
              <img
                style={{ height: 20, width: 20 }}
                src={iconPathURL("common/close.png")}
              ></img>
            </button>
            <div className="row  modal-view">
              <div className="col-xs-12 col-md-3 col-lg-3">
                <img
                  src={iconPathURL(`sustainability/${speakerContent.image}`)}
                  style={{ width: "80%" }}
                ></img>
              </div>
              <div className="col-xs-12 col-md-8 col-lg-8">
                <span className="speaker-name">{speakerContent.name}</span>
                <p className="modal-contents">{speakerContent.designation}</p>
                <p className="modal-contents">
                  LinkedIn Url:
                  <br />
                  <a target="new" href={speakerContent.url}>
                    {speakerContent.url}
                  </a>
                </p>
              </div>
            </div>
            <div className="profile-desc">
              <p className="modal-contents">{speakerContent.details1}</p>
              <p className="modal-contents">{speakerContent.details2}</p>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  const registerPopup = () => {
    return (
      <div className="popup-content">
        <p class="title">
          Registered Participants click here to watch the event now!
        </p>
        <p className="pop-details">
          *Only the registered attendees can watch the livestreaming of the
          event.
        </p>
        <a
          target="new"
          href={"https://webcast.meetyoo.de/index.html?e=wpyiZds1GW0O"}
        >
          <Button
            style="know-more-button-content content"
            popUp="true"
            label="Click Here"
          />
        </a>
      </div>
    );
  };
  const CardView1 = (props) => {
    console.log("ppppp", props);

    const body = () => {
      return (
        <div>
          <th>{props.item.name1}</th>
          <div>
            <p>
              <strong>
                <p className="describe">{props.item.designation1}</p>
              </strong>
            </p>
            <th
              style={{ paddingTop: 15, cursor: "pointer" }}
              onClick={() => {
                setName(props.item.name1);
                setModalVisibility(true);
              }}
            >
              Speaker Details
              <img
                className="img-arrow"
                src={iconPathURL("sustainability/arrow.png")}
              ></img>
            </th>
          </div>
        </div>
      );
    };
    return (
      <div className="text-container-section col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <TwoColumnLayout
          imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
          style="col-xs-12 col-sm-8 col-md-8 col-lg-8 second-column"
          imgSrc={
            props.item.image1 &&
            `${iconPathURL(`sustainability/${props.item.image1}`)}`
          }
          body={props.item.image1 && body()}
        ></TwoColumnLayout>
      </div>
    );
  };
  const CardView2 = (props) => {
    console.log("ppppp", props);

    const body = () => {
      return (
        <div>
          <th>{props.item.name2}</th>
          <div>
            <p>
              <strong>
                <p className="describe">{props.item.designation2}</p>
              </strong>
            </p>
            <th
              style={{ paddingTop: 25, cursor: "pointer" }}
              onClick={() => {
                setName(props.item.name2);
                setModalVisibility(true);
              }}
            >
              Speaker Details
              <img
                className="img-arrow"
                src={iconPathURL("sustainability/arrow.png")}
              ></img>
            </th>
          </div>
        </div>
      );
    };
    return (
      <div className="text-container-section col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <TwoColumnLayout
          imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
          style="col-xs-12 col-sm-8 col-md-8 col-lg-8 second-column"
          imgSrc={`${iconPathURL(`sustainability/${props.item.image2}`)}`}
          body={body()}
        ></TwoColumnLayout>
      </div>
    );
  };
  const sustainabilityTable = () => {
    return (
      <div
        ref={agendaRef}
        className={
          navigate.location.pathname == "/sustainability-23"
            ? "table-content"
            : navigator.platform.indexOf("Mac") == -1
            ? "table-content table-win"
            : "table-content table-mac"
        }
      >
        <div className="first-table">
          <h2
            className={
              navigate.location.pathname == "/sustainability-23"
                ? "agenda-text"
                : "agenda-text-24"
            }
          >
            Agenda
          </h2>
        </div>
        {navigate.location.pathname == "/sustainability-23" ? (
          <div className="head">
            <div className="col-xs-12 col-md-4 col-lg-4 time-text">Time</div>
            <div className="col-xs-12 col-md-4 col-lg-4 time-text">Session</div>
            <div
              className="col-xs-12 col-md-4 col-lg-4 time-text"
              style={
                navigate.location.pathname == "/sustainability-24"
                  ? { textAlign: "center" }
                  : { textAlign: "left" }
              }
            >
              Topic
            </div>
            {navigate.location.pathname == "/sustainability-23" && (
              <div className="col-xs-12 col-md-4 col-lg-4 time-text">
                Speakers
              </div>
            )}
          </div>
        ) : (
          <div className="sustainability-container">
            <div className="time-coulmn time-text">Time</div>
            <div className="session-coulmn time-text">Session</div>
            {navigate.location.pathname == "/sustainability-23" && (
              <div className=" time-text">Speakers</div>
            )}
          </div>
        )}

        {navigate.location.pathname == "/sustainability-23" ? (
          sustainData?.map((item) => (
            <div className="row contents">
              <div className="col-xs-12 col-md-4 col-lg-4 date-text">
                {item.date}
              </div>
              <div className="col-xs-12 col-md-3 col-lg-3 date-text">
                {item.topic}
              </div>
              <div className="col-xs-12 col-md-5 col-lg-5  list-text">
                <ul>
                  {item.p1 && <li>{item.p1}</li>}
                  {item.p2 && <li>{item.p2}</li>}
                  {item.p3 && <li>{item.p3}</li>}
                  {item.p4 && <li>{item.p4}</li>}
                  {item.p5 && <li>{item.p5}</li>}
                </ul>
              </div>
            </div>
          ))
        ) : (
          <>
            {sustainData?.map((item) => (
              <div className="row contents sustainability-wrapper">
                <div className="time-coulmn-data">{item.date}</div>
                <div className="session-column-data">{item.session}</div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };
  const speakerTable = () => {
    return (
      <div ref={speakerRef}>
        {navigate.location.pathname == "/sustainability-23" && (
          <div className="table-content">
            <table>
              <thead>
                <tr className="header-table">
                  <th className="title-text">Mercedes-Benz Speakers</th>

                  <th className="title-text">Guest Speakers</th>
                </tr>
              </thead>
            </table>
            <table>
              <div>
                {speakerData?.map((item, index) => (
                  <div className="speaker-item">
                    <div className="col-xs-12 col-md-6 col-lg-6 date-text">
                      <CardView1 item={item} index={index} />
                    </div>

                    {/* <td width="50%"> </td> */}
                    <div className="col-xs-12 col-md-6 col-lg-6 date-text">
                      <CardView2 item={item} index={index} />
                    </div>
                  </div>
                ))}
              </div>
            </table>
          </div>
        )}
      </div>
    );
  };

  const handleRegisterNow =()=>{
    const registerUrl = "https://sustainabilitydialogueindia2024.com/"
    window.open(registerUrl,"_blank")
  }
  console.log("susss", sustainData);
  const executeScroll_agenda = () => agendaRef.current.scrollIntoView();
  const executeScroll_speaker = () => speakerRef.current.scrollIntoView();
  return (
    <div className="sustain-container">
      {modalSpeaker()}

  <div>
  <Banner
        imgSrc={
          navigate.location.pathname == "/sustainability-23"
            ? iconPath + "sustainability/sustainability_23.png"
            : iconPath + "sustainability/sustain_24.png"
        }
        title=""
      />
      {navigate.location.pathname == "/sustainability-24" && (
        <div className="btn-container">
  <button  onClick={handleRegisterNow} className="register-now-btn">
         Register Now
      </button>
        </div>
      
      )}
  </div>

      {/* <img className="logo-sustain"  src={iconPath+'sustainability/sustain_logo.png'}></img> */}
      {/* <div className="ehs-details">
                  <p className="ehs-p1">{data_24.title_desc.p1}</p>
                        <p className="ehs-p2">{data_24.title_desc.p2}</p>
                        <p className="ehs-p2">{data_24.title_desc.p2}</p>
                        </div> */}

      {/* <img className="img-sustain" src={`${iconPath}sustain.png`}></img> */}
      {navigate.location.pathname == "/sustainability-23" && (
        <section
          className="img-sustain"
          style={{
            backgroundImage: `url(${iconPathURL("sustainability/sustain.png")}`,
            backgroundRepeat: "round",
          }}
        >
          {registerPopup()}
        </section>
      )}

      {navigate.location.pathname == "/sustainability-23" && (
        <section
          className="img-sustain-mobile"
          style={{
            backgroundImage: `url(${iconPathURL(
              `sustainability/sustain_mobile.png`
            )}`,
            backgroundRepeat: "round",
          }}
        >
          {registerPopup()}
        </section>
      )}

      {navigate.location.pathname == "/sustainability-23" && (
        <div className="parent-link">
          <div className="col-xs-12 col-md-6 col-lg-6 link-content">
            <a onClick={executeScroll_agenda}>
              <p
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Agenda Panel Discussions
              </p>
            </a>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6 ">
            <a onClick={executeScroll_speaker}>
              <p
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Speakers{" "}
              </p>
            </a>
          </div>
        </div>
      )}
      {navigate.location.pathname == "/sustainability-23" && (
        <p className="title-name">
          Agenda of the Public Stakeholder Dialogue [Livestream]" January 19
          2023"
        </p>
      )}

      {sustainabilityTable()}
      {navigate.location.pathname == "/sustainability-23" && (
        <p className="title-name">Speakers</p>
      )}

      {speakerTable()}
      {/* <h2 className="about-title">{aboutData.title}</h2>
            <p className="para-content">{aboutData.p1}</p>
            <p className="para-content">{aboutData.p2}</p>
            <p className="para-content">{aboutData.p3}</p> */}
    </div>
  );
}
