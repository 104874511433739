import React, { useState, useEffect } from "react";
import "./aboutUs.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import data from './aboutUs.json'
import { Loader } from "../../common/Loader/Loader";
import iconPathURL from "../../common/iconPath";
import { logAnalyticsEvent } from "../../utils/firebaseConfig";

const iconPath = process.env.PUBLIC_URL + "/assets/images/aboutUs/";

export default function AboutUs(props) {
  const [isVisible, setVisibility] = useState(false);
  const aboutData = data.data;
  const [loading, setLoading] = useState(true);

  logAnalyticsEvent("MBRDI_About");

  useEffect(()=>{
  
    //  window.scrollTo(0, 0);
    setTimeout(()=>{
      setLoading(false)
    },100)
  },[])
    return (
        <div className="about-container">
        {loading &&<Loader></Loader>}
        <Banner imgSrc={iconPathURL('aboutUs/about_us.png')} title="About us" />
          <section className="text-container-section col-xs-12 col-sm-12 col-md-12 col-lg-10">
          <div className="img-content">
          <div className="second-content">
            <h2 className="about-title">{aboutData.title}</h2>
            <p className="para-content">{aboutData.p1}</p>
            <p className="para-content">{aboutData.p2}</p>
            <p className="para-content">{aboutData.p3}</p>
          </div>
          </div>
          </section>
          <img className="img-content footer-content" src={iconPathURL('aboutUs/footer.png')}></img>
        </div>
    )
  }