import React, { useState, useEffect } from "react";
import "./electricRoutes.scss";
import "intersection-observer";
import Carousel from "react-multi-carousel";
import ReactCarousal from "../../common/carousel/carousel";
import "react-multi-carousel/lib/styles.css";
import CustomDots from "../../components/CustomDots/customDots"
import data from "./routes.json"
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";


export default function ElectricRoutes(props) {

  const routeData = data.data;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const hairLine =()=>{
    return(
        <hr
        style={{
          color: '#d3d3d3',
          margin:20,
          height: 2,
          width: "100%"
        }}
      />
    )
  }
  
    return (
        <div className="electric-container">
          
         
          
          <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
       
          <img className="electric-content" src={iconPathURL('innovations/innovation_4.png')}/>
            <h3 style={{margin:13,fontSize:27}}>Taking the Electric Route</h3>
            
            <div className="route-parent">
              
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 routes">
              <img className="icons" src={iconPathURL('common/person.png')}/>
              <p className="electric-details" style={{color:'#4a4a4a'}}>MBRDICOIN_ADMIN</p>
              </div>
             
              <div  className="col-xs-12 col-sm-12 col-md-4 col-lg-4 routes">
              <img className="icons" src={iconPathURL('common/schedule.png')}/>
              <p className="electric-details" style={{color:'#4a4a4a'}}>September 22, 2022</p>
              </div>
           
              <div  className="col-xs-12 col-sm-12 col-md-4 col-lg-4 routes">
              <img className="icons" src={iconPathURL('common/folder.png')}/>
              <p className="electric-details" style={{color:'#4a4a4a'}}>Electric Car</p>
              </div>
              </div>
            {hairLine()}

            <div className="route-details">
            <p className="electric-title">{routeData.title1}</p>
            <p className="electric-details">{routeData.span}</p>
            <p><span className="electric-title">{routeData.title2}</span> <span className="electric-details">{routeData.p2}</span></p>
            <p><span className="electric-title">{routeData.title3}</span> <span className="electric-details">{routeData.p3}</span></p>
            <div className="second-route">
            <p><span className="electric-title">{routeData.title4}</span> <span className="electric-details">{routeData.p4}</span></p>
            <p><span className="electric-title">{routeData.title5}</span> <span className="electric-details">{routeData.p5}</span></p>
            <div className="num-content">
            <p className="electric-details">1. {routeData.num1}</p>
            <p className="electric-details">2. {routeData.num2}</p>
            <p className="electric-details">3. {routeData.num3}</p>
            <p className="electric-details">4. {routeData.num4}</p>
            <img className="route-phone"  src={iconPathURL('routes/route_1.png')}/>
            <p className="electric-details team">{routeData.p6}</p>
            </div>
            </div>
            <p className="electric-details team-top">{routeData.p7}</p>
            
            <p className="title-route">Mercedes-Benz team behind this innovation</p>
            </div>
           
    
          <div className="carousel-route-content-desktop" >
            <ReactCarousal>
          <div>
          <div className="carousel-content-1">
              <img className="carousel-img"   src={iconPathURL('routes/route_2.png')}></img>
              <img  className="carousel-img"src={iconPathURL('routes/route_3.png')}></img>
              <img className="carousel-img" src={iconPathURL('routes/route_4.png')}></img>
              </div>
              </div>

          <div>
          <div className="carousel-content-1">
              <img className="carousel-img" src={iconPathURL('routes/route_5.png')}></img>
              <img  className="carousel-img" src={iconPathURL('routes/route_6.png')}></img>
              <img className="carousel-img"src={iconPathURL('routes/route_7.png')}></img>
              </div>
              </div>
              <div>
              <div className="carousel-content">
              <img className="carousel-trail-img"  src={iconPathURL('routes/route_8.png')}></img>
              <img className="carousel-trail-img" src={iconPathURL('routes/route_9.png')}></img>
              </div>
              </div>
              </ReactCarousal>
            </div>  
            <div className="carousel-route-content-mobile">
            <ReactCarousal>
          
              <div><img height={"50%"} width="100%"  src={iconPathURL('routes/route_2.png')}></img></div>
              <div><img width="100%" src={iconPathURL('routes/route_3.png')}></img></div>
              <div><img width="100%" src={iconPathURL('routes/route_4.png')}></img></div>
              <div><img width="100%" src={iconPathURL('routes/route_5.png')}></img></div>
              <div><img  width="100%" src={iconPathURL('routes/route_6.png')}></img></div>
              <div><img  width="100%" src={iconPathURL('routes/route_7.png')}></img></div>
              <div><img  width="100%" src={iconPathURL('routes/route_8.png')}></img></div>
              <div><img  width="100%" src={iconPathURL('routes/route_9.png')}></img></div>
            </ReactCarousal>
            </div>
            <div className="foot-details">
            <img className="lower-content" src={iconPathURL('routes/routes_10.png')}></img>

            <p className="electric-foot">{routeData.p8}</p>
            </div>
          {hairLine()}
          {/* <img className="img-content footer-content" src={`${iconPath}footer.png`}></img> */}
          </section>
        </div>
    )
  }
