
import React, { Suspense } from "react";
import { useHistory } from "react-router-dom";
import docPathURL from "../docPath";
import iconPathURL from "../iconPath";
import "./button.scss"
// import docs from "../../../public/assets/docs"
const iconPath = process.env.PUBLIC_URL + "/assets/docs/";
export default function Button(props) {
  const navigate = useHistory();
  const handleDocs = (doc) => {
 
     // using Java Script method to get PDF file
     fetch(`${iconPath}${doc}`).then((response) => {
       response.blob().then((blob) => {
         // Creating new object of PDF file
         const fileURL = window.URL.createObjectURL(blob); // Setting various property values
         let alink = document.createElement("a");
         alink.href = fileURL;
         alink.download = "file.docx";
         alink.click();
       });
     });
   };

  const handleClick=()=>{
    // if(props.type== "pdf"){
    //   handlePdfLoader
    // }
    navigate.push(props.url)
  }
    console.log("docs",props.type);
  return (
    <div className={props.style}>
      {props.type=="pdf"?<a target="new" href={`${iconPath}${props.doc}`}>
        <button className="common-button">{props.label}</button>
        </a>:
        props.type=="url"?<a target="new" href={props.doc}>
        <button  className="common-button">{props.label}</button>
        </a>:
        props.type=="doc"?<button onClick={()=>handleDocs(props.doc)} className="common-button">{props.label}</button>
        :
        props.type=="img"?<button onClick={props.handleModal} className="common-button">{props.label}</button>
        :
        <button style={props.popUp&&{backgroundColor:'#5082EC',fontSize:10}} onClick={handleClick} className="common-button">{props.label}</button>
        }
      {/* <button onClick={onButtonClick}> Download PDF
 </button> */}
            {/* <button onClick={handleClick} className="common-button">{props.label}</button> */}
    </div>
  );
}