import React, { useState, useEffect } from "react";
import "./Benefits.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";


export default function Benefits(props) {
    return (
      <div className="benifits-container">
      <Banner imgSrc={iconPathURL('benifits/benifits.png')} title="Benefits" />

      <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <div className="first-box">
            <p className="first-text">We have a clear focus: people. And to that end, we have carved our own road around the kind of employee experience we wish to offer. The experience being seamless and uniform across our Bengaluru and Pune locations is our constant endeavour with some of the most progressive employment benefits and people policies.

          </p>
              <h2 className="address">The Learning Never Stops</h2>
              <div>

                <p className="second-text">As a Development Centre, we are a learning organisation, relying heavily on innovation and disruptive thinking. Towards this, we back every employee initiative around educating themselves. The typical MBRDI employee is always a work-in-progress, because there’s a world of options ahead of them in their pursuit of self-upgrade, including:</p>
                  <p>
                  <ul>
                    <li>
                    The option to do short-term technical courses from either the highly sophisticated L&D ecosystem developed internally or through academia externally
                    </li>
                    <li>
                    Pursuing higher education – be it an M.Tech or a Ph.D degree
                    </li>
                    <li>
                    The skill development modules one needs to take on, progressing through varied levels in one’s career ladder
                    </li>
                    <li>
                    The advanced language courses (German or Japanese), promising to make well-rounded professionals of them
                    </li>
                  </ul>
                  </p>
                <p>
                An MBRDI professional therefore, is encouraged to keep an ever-agile mindset to meet challenges of the future.
                </p>

              </div>
            
          </div>

        

          <div className="second-box">
              <h2 className="address">Work Hard and Live Fully</h2>
           
              <p className="second-text">MBRDI espouses individual needs for fruitful professional engagement balanced with a life of one’s choosing. Advocating an early start to the day with an early finish, it is the work-life balance this enables which our employees hail among other facets of employment here, including the ample vacation days in a calendar year.</p>
              <p className="third-text">
                Aside of flexibilities to work from home, an enablement to do so for a prolonged time, allows for attending to responsibilities or emergencies while balancing work priorities.
              </p>

            
          </div>

          <div className="third-box">
              <h2 className="address">Alignment with the Fundamentals</h2>
            
              <p className="second-text">MBRDI believes that it is right from the start of one’s journey with the company, that cognisance to some crucial aspects, ultimately leading to an aligned community of individuals founded on trust and respect, is key. Right at the beginning, every individual is brought up-to-speed with essentials characterising the company’s intrinsic culture, including the Integrity policy, Data Protection Policy, and the Disciplinary Policy.  </p>
             
          </div>

          <div className="foutrh-box">
              <h2 className="address">Stability, Safety, Security</h2>
            
              <p className="first-text">MBRDI has a sophisticated blend of cross-functional programmes, and internal job postings promoting lateral growth. There is a whole catalogue of Wellness efforts paramount among priorities in the interest of employees, as well catering to seasonal concerns, as well as the archetypal working professionals’ concerns on ergonomics, to issues around stress and anxiety.. The experience thus is centred at offering stability or long-term careers, in a holistic manner.   </p>
              <p className="second-text">While employers are bound to offer augmented financial security through schemes around insurance and pensions, we believe in presenting flexibility to chart out individual financial plans in accordance with personal needs and goals. The Flexi Benefits policy bestows that power in the MBRDI employees’ hands, allowing for redemption of unused points to avail a host of assorted benefits. </p>
             
          </div>
          <div className="fifth-box">
              <h2 className="address">Fostering a Diverse Workplace</h2>
            
              <p className="first-text">Employees rely on MBRDI as an an enabling influence when they progress on to embrace newer roles in their own lives. This is especially true of mothers-to-be, for whom we bring together a range of added benefits such as part-time working, Bond with Baby leaves, Cool Travel (i.e., personal, comfortable shuttle) for expectant mothers, Young Mom’s Kitty and child-care benefits, over and above the default maternity leaves and crèche facilities companies are wont to provide.</p>
            
             
          </div>
          <div className="sixth-box">
              <h2 className="address">Tech-enabled Day-to-Day processes</h2>
            
              <p className="first-text">From digitalised processes for claims and reimbursements for higher education, short-term certifications, relocation or commute, to automation in payouts making it hassle-free for beneficiaries under the Young Mom’s Kitty or inter-city transfer process, we’ve put in place all elements of digital convenience that enables employees to mind transactional routines without telling on productivity.

</p>
            
             
          </div>
      </section>

    
  </div>
    )
  }