import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import { Button } from "./style";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);
  const iconPath = process.env.PUBLIC_URL + "/assets/images/common/";
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Button style={{marginBottom:30 }}>
        <i
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none"}}
          class="fa fa-angle-up"
          aria-hidden="true"
          role="img"
        >
			 <img style={{height:15,width:15}}  src={`${iconPath}up_arrow.png`}></img>
        </i>
        {/* <FaArrowCircleUp onClick={scrollToTop}
	style={{display: visible ? 'inline' : 'none'}} /> */}
    </Button>
  );
};

export default ScrollButton;
