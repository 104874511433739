import React, { useState, useEffect } from "react";
import "./Csr.scss";
import "intersection-observer";
import Button from "../../common/Buttons/button";
// import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";
import ContactFooter from "../../common/ContactFooter/contactFooter";
import { logAnalyticsEvent } from "../../utils/firebaseConfig";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";
const docPath = process.env.PUBLIC_URL + "/assets/docs/";

logAnalyticsEvent("CSR");

export default function Csr(props) {
  const [imageContainerClass, setClass] = useState(
    "col-lg-2 col-xs-7 col-sm-3 col-md-2"
  );
  const [imageContainerClass1, setClass1] = useState(
    "col-lg-2 col-xs-12 col-sm-3 col-md-2"
  );

  const saveHitsCount = (event) => {
    // console.log("event name", event);
    logAnalyticsEvent(event);
  };

  return (
    <div className="csr-container">
      <div className="csr-banner-container">
        <img src={iconPathURL("csr/csr.png")} className="banner-image" />

        <div className="banner-content">
          <h2 className="banner-title">Corporate Social Responsibility</h2>
          <div className="btn-container">
            <Button
              label="MBRDI CSR Policy"
              type="pdf"
              doc="CSR_Policy_V9.0.pdf"
            ></Button>
            <Button
              label="Annual Action Plan"
              type="pdf"
              doc="MBRDI_Annual_Action_Plan_V2.pdf"
            ></Button>
          </div>
        </div>
      </div>

      <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <div className="first-box">
          <h2 className="sub-title title-content">
            Tomorrow drives Mercedes-Benz
          </h2>
          <p className="first-text address">
            Mercedes-Benz Group AG believes that the concept of mobility is to
            “First Move the World”. Its objective is to be the leading luxury
            vehicle manufacturer in the world. With this comes the obligation of
            the brand to not only look at moving people from point A to B, but
            also commit to a sustainable and future-proof society together with
            its employees.
          </p>
          <p className="second-text">
            MBRDI aspires to have a lasting positive impact on the world, and we
            are passionately pursuing our social responsibilities as we
            strategically invest in projects contributing towards safer roads,
            integrated mobility, and environmental sustainability for a better
            tomorrow. As a member of the United Nations Global Compact, our
            projects are fervently designed in alignment with the United Nations
            – Sustainable Development Goals and the larger Sustainability tenets
            of Mercedes-Benz AG.
          </p>
        </div>
        <div className="second-box">
          <h2 className="title-content">
            The MBRDI CSR Committee consists of the following members:
          </h2>
          <div className="row profile-image-contanier">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <img
                className="csr-image"
                src={iconPathURL("csr/profile1.png")}
              ></img>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <img
                className="csr-image"
                src={iconPathURL("csr/profile2.png")}
              ></img>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <img
                className="csr-image"
                src={iconPathURL("csr/profile3.png")}
              ></img>
            </div>
          </div>
        </div>

        <div className="thirde-box">
          <h2 className="main-title title-content">CSR Focus Areas:</h2>

          <img
            src={iconPathURL("csr/csr-pillars.png")}
            className="banner-image"
          />

          <h2 className="sub-title title-content">Sustainable Mobility</h2>

          <p className="first-text">
            According to a study conducted in 2018, India accounts for roughly
            one percent of the world’s vehicle population, the country also
            reported about six percent of the global road traffic incidents. The
            sub-continent reported around 151,000 fatalities due to road
            accidents.
          </p>
          <p className="second-text">
            In this backdrop, improving urban mobility and traffic safety are
            two tenets of our mobility projects under which we are committed to
            raising road safety awareness, enabling innovation to solve common
            mobility problems and championing collaborative eco-systems for
            collective action.
          </p>
          <p className="second-text">
            Further, our 15-Minute Neighbourhood project addresses the need for
            sustainable urban planning in India. The guidelines created as part
            of this project along with an implementation toolkit highlight key
            strategies that cities can employ to facilitate a 15-minute
            neighbourhood, where most amenities are accessible within a
            15-minute walk or cycle ride. This approach enhances the quality of
            life by promoting high-quality pedestrian and public transport
            systems, safe public spaces, and inclusive social infrastructure.
          </p>
        </div>

        <div className="foutrh-box">
          <h2 className="title-content">Environmental Sustainability</h2>

          <p className="first-text">
            We undertake initiatives targeted at resource conservation, climate
            protection and improved air quality by preserving urban landscapes
            and cultivating a culture of community ownership and stakeholder
            engagement.
          </p>
        </div>

        <div className="fifth-box">
          <h2 className="title-content">Need Based</h2>

          <p className="first-text">
            We value our role as enablers of creating a barrier-free world.
            Through carefully put together interventions, our work is currently
            focused on launching careers, and promoting employability for
            Persons with Disabilities.
          </p>
          <p className="second-text">
            Around 2.2 percent of India’s population subsists with some form of
            disability. We recognise that lack of equal opportunities, plays a
            part in their inability to nurture the right skill sets and know how
            to be employment-ready and in turn, curbs independence.
          </p>
          <p className="third-text">
            Therefore, targeted at financial independence and providing a
            dignified standard of living, MBRDI has invested in empowering
            Persons with Disabilities. These are undertaken through technical
            skilling programs, distribution of mobility aids and equipment and
            income generation projects.
          </p>
        </div>

        <div>
          <h2 className="title-content">Click here for:</h2>
          <a
            className="email-link"
            target="new"
            href={`${docPath}FY2022-2023 Annual Report.pdf`}
          >
            {"FY2022-2023 Annual Report"}{" "}
          </a>
          <br></br>
          <br></br>
          <a
            className="email-link"
            target="new"
            href={`${docPath}FY2023-2024 Annual Report.pdf`}
          >
            {"FY2023-2024 Annual Report"}{" "}
          </a>
          <br></br>
          <br></br>
          <a
            className="email-link"
            target="new"
            onClick={() => saveHitsCount("Neighbourhood_Guidelines")}
            href={`${docPath}Building 15-Minute Neighbourhoods-Design Guidelines.pdf`}
          >
            {"15-Minute Neighbourhood Design Guidelines"}{" "}
          </a>
          <br></br>
          <br></br>
          <a
            className="email-link"
            target="new"
            onClick={() => saveHitsCount("Neighbourhood_Implementation")}
            href={`${docPath}Building 15-Minute Neighbourhoods-Implementation Toolkit.pdf`}
          >
            {"15-Minute Neighbourhood Implementation Toolkit"}{" "}
          </a>
        </div>
      </section>
      <ContactFooter
        html={() => {
          return (
            <span>
              For any queries related to CSR, please write to
              <a
                className="email-link"
                href="mailto:  csr-mbrdi@mercedes-benz.com"
              >
                {"  csr-mbrdi@mercedes-benz.com"}{" "}
              </a>
            </span>
          );
        }}
        screen="ehs"
        label="For any queries on EHS, please write to "
      />
    </div>
  );
}
