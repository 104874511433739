import React, { useState, useEffect } from "react";
import "./peoplePractise.scss";
import "intersection-observer";
import data from './practise.json'
import Banner from "../../common/Banner/banner"
import TwoColumnLayout from "../../common/TwoColumnLayout/TwoColumnLayout";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";

  const CardView = (props) => {
    const body = () => {
      return (
        <div>
          <h2 className="address">{props.item.p1}</h2>
          <div>
          <p >{props.item.details1}</p>
          <p >{props.item.details2}</p>
          </div>
        </div>
      );
    };
    return (
      <div className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <TwoColumnLayout
          style="col-xs-12 col-sm-10 col-md-8 col-lg-11 second-column"
          imgStyle="col-xs-12 col-sm-2 col-md-4 col-lg-1 first-column"
          imgSrc={iconPathURL(`practise/${props.item.image}`)}
          body={body()}
        ></TwoColumnLayout>
      </div>
    );
  };



export default function PeoplePractise(props) {

  const practiseData = data.data;

    return (
        <div className="people-container" >
          <Banner
            imgSrc={iconPathURL('practise/practise_header.png')}
            title="People Practices"
          />
                <div >
                {/* <div className="img-content ">
          <img className="img-content " src={`${iconPath}practise/practise_header.png`}></img>
           <h1 className="press-text">People Practices</h1>
        </div>     */}
        <div >
           {practiseData.list.map((item,index)=>{return(
                <CardView
                item={item}
                index={index}
                /> 
            )})}   
       </div> 

       </div>
       {/* <div className="row">
        <div className="col-lg-1 col-xs-12 col-sm-2 col-md-2">
        <img className="ehs-img" src={`${iconPath}/practise/practise_1.png`} />
        </div>
        <div className="col-lg-11 col-sm-10 col-md-10">
        <div>Besides the technical, behavioural, leadership and language interventions, we also conduct practical experience through ‘Hands-on-Benz’, an in-house, state-of-the-art mechanical garage, allowing for working with hardware components of our cars and commercial vehicles. Our employees also benefit from pursuing higher education programmes, that we actively encourage and enjoy online library services.</div>
        </div>
        </div> */}
    </div>
    )
  }