import axios from "axios";
export const TokenService = {
  getSaasToken,
  getRefreshToken1,
  getToken,
  getSaasToken1,
};
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer uvjjTqDHTlCFUtY51yVL5QJmpouUCrELCKTbvzuXA/M=",
};

function getRefreshToken1() {
  return axios.get(
    "https://web-xqs3lzhgwmmfg-react.azurewebsites.net/user-service/user/getMessage",
    {
      headers: headers,
    }
  );
}

function getToken() {
  return axios.get(process.env.REACT_APP_TOKEN_URL, {
    auth: {
      username: process.env.REACT_APP_USER,
      password: process.env.REACT_APP_PASSWORD,
    },

    headers: {
      "Content-Type": "application/json",
    },
  });
}
function getSaasToken(token) {
  if (token == null) {
    token = process.env.REACT_APP_TOKEN;
  }

  return axios.get(process.env.REACT_APP_SAS_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function getSaasToken1(token) {
  return axios.get(
    "https://oneapp-services-dev.azurewebsites.net/file-service/get-sas-token/mbrdi-website",
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
