import React, { useState,useEffect } from "react";
import "./layout1.scss";
import Button from "../Buttons/button"
import iconPathURL from "../iconPath";
const iconPath = process.env.PUBLIC_URL + '/assets/images/home/';

export default function Layout1(props) {


  return (
    <div
      className="common-template"
    >
  <section  style={{ backgroundImage: `url(${iconPathURL(`home/${props.img}`)})`, backgroundRepeat: "round" }} className={props.classParentName}>
             <div className={props.className}>
              
                 <div className="margin-auto first-content-text">
                     <h2 className="heading-sub-title">{props.title}</h2>
                     <p className="content-layout">{props.content}</p>
                     <a href={props.type=='ehs'?'#/ehs':'#/join-star'}><Button label = "Know more" ></Button></a>
                   </div>
                  
             </div>
         </section>
     
    </div>
  );
}