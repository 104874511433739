import React from "react";
import "./Loader.scss";

export function Loader() {

    return (
        <div>
            <div id="loader" className="loading">Loading&#8230;</div>
        </div>

    )
}

