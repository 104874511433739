import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { propTypes } from "react-bootstrap/esm/Image";

export default function ReactCarousel(props,{children }) {
  console.log('props..',props,children);
  return (
    <Carousel
      showThumbs={false}
      key={props.count}
      swipeable={true}
      autoPlay={2000}
      emulateTouch={true}
      infiniteLoop={true}
      showStatus={false}
      width="100%"
      stopOnHover={true}
      slidesToShow="3"
      {...props}
    >
      {props.children}
    </Carousel>
  );
}
