import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import "intersection-observer";
import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";
import TwoColumnLayout from "../../common/TwoColumnLayout/TwoColumnLayout";
import ContactFooter from "../../common/ContactFooter/contactFooter";
const iconPath = process.env.PUBLIC_URL + "/assets/images/";

export default function ContactUs(props) {
  console.log(props);
  const [addressDetails, setAddressDetails] = useState([
    {
      title: "Mercedes-Benz Research and Development India Private Limited",
      text: "Registered Office : Embassy Crest, Plot No. 5-P, EPIP Zone, Phase 1, Whitefield Road, Bengaluru, Karnataka, India, 560066",
      id_number: "Corporate Identity Number : U74110KA1996PTC021375",
      p1: "Telephone No. + 91 80 6150 6600",
      p2: "Telephone No. + 91 80 6150 6601",
      p3: "Email: mbrdi_corp@mercedes-benz.com",
    },
    {
      title: "Brigade Tech Gardens",
      text: "SEZ Unit : Tower B3 Ground 1,2,3 floor Brigade Tech Gardens-SEZ Brigade properties Pvt.LTD Katha No. 119 Survey No. 103, 104, 105, 108/1, 108/2, 109 (P), 112 & 113/1B (P)  Brookefields, Kundalahalli Village, K.R. Puram Hobli, Marathalli Post,  Bengaluru, Karnataka- 560037",
      p1: "Telephone No. +91 80 6852 6900",
      p2: "Telephone No. +91 80 6852 6911",
    },
    {
      title: "Embassy Tech Zone",
      text: "Embassy Tech Zone, Building Congo, Block 1.3, Rajiv Gandhi Infotech Park, Hinjewadi Phase II, Pune – 411057",
      p1: "Telephone No.  +91 20 6695 7000",
      p2: "",
    },
  ]);
  function body(data) {
    return (
      <div>
        <h2 className="address">{data["title"]}</h2>
        <p>{data.text}</p>
        <p>{data.id_number}</p>
        <div>
          <p>
            <strong>{data.p1}</strong>
            <br />
            <strong>{data.p2}</strong>
            <br /> <br />
            <strong>{data.p3}</strong>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="contact-us-container">
      {props.token}
      <Banner
        imgSrc={iconPathURL("contactUs/contactUs.png")}
        title="Contact Us"
      />

      <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <div className="">
          <h2 className="location-title">Our locations</h2>
        </div>
      </section>
      <div className="element-divider"></div>
      <section className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <div className="contact1">
          <h2 className="location-title">Bengaluru</h2>

          <TwoColumnLayout
            imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
            style="col-xs-12 col-sm-6 col-md-6 col-lg-6 second-column"
            imgSrc={iconPathURL("contactUs/location1.png")}
            body={body(addressDetails[0])}
          ></TwoColumnLayout>
          <TwoColumnLayout
            imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
            style="col-xs-12 col-sm-6 col-md-6 col-lg-6 second-column"
            imgSrc={iconPathURL("contactUs/location2.png")}
            body={body(addressDetails[1])}
          ></TwoColumnLayout>
        </div>
        <div className="contact2">
          <h2 className="location-title">Pune</h2>
          <TwoColumnLayout
            imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
            style="col-xs-12 col-sm-6 col-md-6 col-lg-6 second-column"
            imgSrc={iconPathURL("contactUs/location3.png")}
            body={body(addressDetails[2])}
          ></TwoColumnLayout>
        </div>
      </section>
      {/* <div className="element-divider-content">
          <div className="element-divider"></div>
        </div> */}
      <ContactFooter
        html={() => {
          return (
            <span>
              In case of any queries or grievances under data processing please
              reach out to us at
              <a
                className="email-link"
                href="mailto: legalandcompliance_mbrdi@mercedes-benz.com"
              >
                {" legalandcompliance_mbrdi@mercedes-benz.com"}{" "}
              </a>
            </span>
          );
        }}
        screen="contact"
        label="In case of any queries or grievances under data processing please reach out to us at"
      />
    </div>
  );
}
