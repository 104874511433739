import React, { useState, useEffect } from "react";
import "./support.scss";
import "intersection-observer";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import Banner from "../../common/Banner/banner";
import data from "./support.json";
import iconPathURL from "../../common/iconPath";
// import details from "./sustainability_2.json";

const iconPath = process.env.PUBLIC_URL + "/assets/images/";

export default function Support(props) {
    const supportData = data.data;
    const navigate = useHistory();
    const hairLine =()=>{
        return(
            <hr
            style={{
              borderColor:'black',
              borderWidth:0.01,
              marginVertical:20,
              height: '0.01px',
              width:"100%"
            }}
          />
        )
      }
    const supportTable=()=>{
        return(
            <div className="support-content">
                 <div
          style={{
            color: '#d3d3d3',
            backgroundColor:'white',
            height:1,
            width:20,
            alignSelf: 'flex-start',
            marginBottom:20,
            marginTop: 20
            //height: 'px',
          }}
        />
                <div className="date-text">Support</div>
            
            <div className="date-text">{supportData.title1}</div>
   
            <div className="mail-content">
            <img className="img-icon" src={iconPathURL('common/mail.png')}></img>
            <span> <a className="email-link" href="mailto:sustainabilitydialogueindia@mercedes-benz.com">
                sustainabilitydialogueindia@mercedes-benz.com</a></span>
                </div>
             
            <div className="date-text">{supportData.title2}</div>
            <ul className="date-text">
                   <li>{supportData.p1}</li>
                  <li>{supportData.p2}</li>
                   <li>{supportData.p3}</li>
                  <li>{supportData.p4}</li>
                  <li>{supportData.p5}</li>
                  <li>{supportData.p6}</li>
                  <li>{supportData.p7}</li>
                  </ul>
            </div>
        )
    }


    return(
        <div className="support-container">
        <Banner className="banner-content" imgSrc={navigate.location.pathname == '/support-23'?iconPath+'sustainability/sustain_header23.png':iconPath+'sustainability/sustainability_24_header.png'} title="" />
        {supportTable()}
        </div>
    )
}