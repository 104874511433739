import React, { useState, useEffect } from "react";
import "./inNews.scss";
import "intersection-observer";
import data from "./inNews.json";
import { Card } from "react-bootstrap";
import Button from "../../common/Buttons/button";
import Modal from "../../common/Modal/modal";
import ContactFooter from "../../common/ContactFooter/contactFooter";
import DetailedView from "../../components/DetailsCarousal/detailedView";
import TwoColumnLayout from "../../common/TwoColumnLayout/TwoColumnLayout";
import Banner from "../../common/Banner/banner";
import iconPathURL from "../../common/iconPath";

const iconPath = process.env.PUBLIC_URL + "/assets/images/inNews/";



export default function InNews(props) {
  const [isVisible, setVisibility] = useState(false);
  const [image, setImage] = useState("");
  const pressData = data.data;

  const CardView = (props) => {
    const body = () => {
      return (
        <div>
          <h2 className="address">{props.item.p1}</h2>
          <div>
            <p>
              <strong>
                <p className="date">
                  {props.item.date}
                </p>
              </strong>
            </p>
            <Button
              style="know-more-button-content content"
              label="Read more"
              doc={props.item.url}
              type={props.item.type}
              handleModal={() => {
                console.log("img...",props.item.url);
                setImage(props.item.url);
                props.setVisibility(true);
              }}
            />
          </div>
        </div>
      );
    };
    return (
      <div className="text-container-section col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <TwoColumnLayout
        imgStyle="col-xs-12 col-sm-4 col-md-4 col-lg-4 first-column"
          style="col-xs-12 col-sm-8 col-md-8 col-lg-8 second-column"
          imgSrc={`${iconPath}${props.item.image}`}
          body={body()}
        ></TwoColumnLayout>
      </div>
    );
  };
  return (
    <div className="in-news-container">
      <Modal show={isVisible} handleClose={() => setVisibility(false)}>
        <button
          className="button-container"
          onClick={() => setVisibility(false)}
        >
          <img
            style={{ height: 20, width: 20 }}
            src={iconPathURL('common/close.png')}
          ></img>
        </button>
        <DetailedView url={image} />
      </Modal>
      <Banner
        imgSrc={iconPathURL('inNews/inNews_header.png')}
        title="In the News"
      />
      <div>
        {pressData.map((item, index) => {
          return (
            <CardView
              item={item}
              index={index}
              setVisibility={(state) => setVisibility(state)}
              isVisible={isVisible}
              setImage={(img) => setImage(img)}
            />
          );
        })}
      </div>
      <ContactFooter
      html={()=>{
        return(
        <span>For media queries, please write to MBRDI Communications<a className="email-link" href="mailto: mbrdi_communications@Mercedes-Benz.com">{" mbrdi_communications@Mercedes-Benz.com"} </a></span>
      )}}
      screen="news"
  
      />
    </div>
  );
}
