import React, { useState, useEffect } from "react";
import "./eventAgenda.scss";
import "intersection-observer";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import Banner from "../../common/Banner/banner";
import data from "./event.json";
import iconPathURL from "../../common/iconPath";
// import details from "./sustainability_2.json";

const iconPath = process.env.PUBLIC_URL + "/assets/images/sustainability/";

export default function EventAgenda(props) {
    const eventData = data.data_1;
    const navigate = useHistory();
    const agendaTable = () => {
        return (
          <div className="table-content">
           
             <div className="first-table" >
                <h2 className="agenda-text">
                Event Agenda | Sustainability Dialogue 2023
                January 19 2023
                </h2>
              </div>
              <div className="head">
                  <div className="col-xs-12 col-md-4 col-lg-4 time-text">Time</div>
              <div className="col-xs-12 col-md-4 col-lg-4 time-text">Topic</div>
              <div className="col-xs-12 col-md-4 col-lg-4 time-text">Speakers</div>
                </div>
            {eventData.map((item) => (
            <div className="row contents">
              
              <div className="col-xs-12 col-md-4 col-lg-4 date-text">{item.date}</div>
              <div className="col-xs-12 col-md-4 col-lg-3 date-text">
                <div className="topic">{item.topic1}</div>
                <div className="topic">{item.topic2}</div>
                <div className="topic">{item.topic3}</div>
                </div>
              
              <div className="col-xs-12 col-md-4 col-lg-5">
              <ul className="date-text">
                        {item.p1 && <li>{item.p1}</li>}
                        {item.p2 && <li>{item.p2}</li>}
                        {item.p3 && <li>{item.p3}</li>}
                        {item.p4 && <li>{item.p4}</li>}
                        {item.p5 && <li>{item.p5}</li>}
                      </ul>
    
              </div>
            </div>
            ))}
          </div>
        );
      };
    return(
        <div className="event-container">
        <Banner className="banner-content" imgSrc={navigate.location.pathname == '/event-agenda-23'?iconPath+'/sustain_header23.png':iconPath+'sustain_header24.png'} title="" />
        {agendaTable()}
        </div>
    )
}